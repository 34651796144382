<template>
  <div id="content" class="politic-confidence container">
    <h1 class="text-center">
      {{ !this.$route.query.lang ? localize('politicConfidence')['title'] : data[this.$route.query.lang].title }}
    </h1>
    <!-- parent -->
    <ol type="1" class="list">
      <li
        v-for="(list, index) in !this.$route.query.lang ? confidenceList : data[this.$route.query.lang].content"
        :key="index">
        {{ list.title }}
        <!-- children -->
        <ol class="child" type="a" v-if="list.children">
          <li v-for="(child, index) in list.children" :key="index">
            {{ child }}
          </li>
        </ol>
      </li>
    </ol>
<!--    <button @click="generatePDF">Print</button>-->
  </div>
</template>

<script>
import localize from '../../filters/localize.filter';
import { versionRu, versionUz } from '../../data/politic-confidence';

export default {
  name: 'PoliticConfidencePage',
  data() {
    return {
      confidenceList: localize('politicConfidence')['content'],
      lang: 'ru',
      data: {
        ru: {
          title: 'Политика конфиденциальности',
          content: versionRu,
        },
        uz: {
          title: 'Мaxfiylik siyosati',
          content: versionUz,
        },
      },
    };
  },
  created() {
    if (this.$route.query.lang && this.$route.query.lang === 'uz') {
      this.lang = this.$route.query.lang;
    }
  },
  methods: {
    localize,
    printPage() {
      const anotherWindow = window.open();
      anotherWindow.document.write(document.querySelector('.politic-confidence').innerHTML);
      window.print();
      window.close();
    },
    generatePDF() {
      var element = document.getElementById('content');
      var opt = {
        margin: 1,
        filename: 'myfile.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' },
      };

      // New Promise-based usage:
      // eslint-disable-next-line
      html2pdf(element, opt);
    },
  },
};
</script>

<style lang="scss" scoped>
  .politic-confidence {
    h1 {
      margin-top: 32px;
    }

    .list {
      margin: 2rem 0;

      li {
        list-style: auto;
      }

      .child {
        li {
          list-style: upper-latin;
        }

        margin-left: 2rem;
      }
    }
  }
</style>
